
import { Component } from 'vue-property-decorator';
import TemplatesToolbar from './TemplatesToolbar.vue';

  @Component({
    components: {
      TemplatesToolbar
    }
  })
export default class TemplatesBottomToolbar extends TemplatesToolbar {
}
